import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import LangSwitcher from "./langswitcher/LangSwitcher";

const Navbar = ({ scrollThreshold = 20 }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    setIsScrolled(window.scrollY > scrollThreshold);
  }, [scrollThreshold]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <NavWrapper isScrolled={isScrolled}>
      <NavBackground isScrolled={isScrolled} />
      <NavContent>
        <MainNavContent>
          <LeftSection>
            <LogoWrapper onClick={() => navigate("/")}>
              <Logo alt="Nightly" />
            </LogoWrapper>
          </LeftSection>
          <RightSection>
            <Button onClick={() => navigate("/business")}>
              {t("hero_section.business_btn")}
            </Button>
            <LangSwitcherWrapper>
              <LangSwitcher />
            </LangSwitcherWrapper>
          </RightSection>
        </MainNavContent>
      </NavContent>
    </NavWrapper>
  );
};

export default Navbar;

const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  transition: background-color 0.3s ease-in-out;
  margin-top: -3rem;
  direction: ltr;

  @media (max-width: 768px) {
    padding-top: 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`;

const NavBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isScrolled }) =>
    isScrolled ? "rgba(28, 28, 23, 0.8)" : "transparent"};
  box-shadow: ${({ isScrolled }) =>
    isScrolled ? "0 1px 4px rgba(0, 0, 0, 0.1)" : "none"};
  border-bottom: ${({ isScrolled }) =>
    isScrolled ? "1px solid #48473a" : "none"};
  backdrop-filter: ${({ isScrolled }) =>
    isScrolled ? "blur(10px)" : "none"}; // Apply blur effect when scrolled
  z-index: -1;
  /* transition: background-color 0.4s ease-in-out, border-bottom 0.4s ease-in-out; */
  transition: background-color 0.4s ease-in-out, border-bottom 0.4s ease-in-out,
    backdrop-filter 0.4s ease-in-out;
  height: 75%;
  width: 150%;
  opacity: 0.99;
  @media (max-width: 768px) {
    top: unset; // Remove bottom positioning to avoid overlap issues
    height: 200%;
  }
`;

const NavContent = styled.nav`
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 2rem 0;
`;

const MainNavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  margin-right: 0.1rem;
  margin-left: -1.5rem;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const LogoWrapper = styled.div`
  cursor: pointer;
  svg {
    height: 1.4rem;
    @media (max-width: 768px) {
      height: 1rem;
      cursor: pointer;
    }
  }
`;

const Button = styled.button`
  font-size: 1.3rem;
  min-width: 150px;
  min-height: 53px;
  background-color: transparent;
  border: 2px solid #939182;
  color: #e9e928;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.22s ease-in;
  margin-right: 1rem;

  &:hover {
    background-color: #22220e;
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    min-width: 120px;
    min-height: 40px;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const LangSwitcherWrapper = styled.div`
  @media (max-width: 768px) {
    position: absolute;
    top: -8.4rem;
    left: 1rem;
  }
`;
