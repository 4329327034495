import React, { useEffect, useState, useRef } from "react";
import i18next from "i18next";
import cookies from "js-cookie";
import "./LangSwitcherStyle.css";

const languages = [
  {
    code: "he",
    name: "עברית",
    country_code: "il",
    dir: "rtl",
  },
  {
    code: "en",
    name: "English",
    country_code: "us",
  },
];

const LangSwitcher = () => {
  const [isActive, setIsActive] = useState(false);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [selected, setSelected] = useState(currentLanguage.name);
  const dropdownRef = useRef(null);
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.documentElement.setAttribute("lang", currentLanguage.code);
    // if (currentLanguage.dir === "rtl") {
    //   document.body.dir = "rtl";
    // }
  }, [currentLanguage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  return (
    <div className="container" ref={dropdownRef}>
      <div className="dropdown">
        <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
          <img
            className="flag-icon-menu"
            src={
              currentLanguage.code === "en"
                ? "/assets/locales/images/us.svg"
                : "/assets/locales/images/il.svg"
            }
            alt=""
          />
          {selected}
        </div>
        {isActive && (
          <div className="dropdown-content">
            {languages.map(({ code, name, country_code }) => (
              <div
                onClick={(e) => {
                  setSelected(name);
                  setIsActive(false);
                  i18next.changeLanguage(code);
                }}
                // style={{ opacity: code === currentLanguageCode? 0.5 : 1 }} // disable the selceted language
                className="dropdown-item"
                key={country_code}
              >
                {/* <span className="flag-icon fi-il.fis"></span>
                <span className="fi-il.fis"></span> */}
                <span className="flag-icon">
                  <img
                    className="flag-icon-svg"
                    src={`/assets/locales/images/${country_code}.svg`}
                    alt=""
                  />
                </span>
                {name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LangSwitcher;
