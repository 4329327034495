import { sha512 } from "js-sha512";
import { ProgressCallback, SignedChallenge } from "./index";

const computeCollisionInteger = async ({
  input,
  expected,
}: {
  input: string;
  expected: string;
}) => {
  let attempt = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const hash = sha512(`${input}:${attempt}`);
    if (attempt % 4e4 === 0) {
      await sleep(0);
    }
    if (hash.substring(0, expected.length) === expected) {
      return attempt.toString();
    }
    attempt++;
  }
};

const sleep = (time: number) => new Promise((r) => setTimeout(r, time));

const computeSolutions = async ({
  challenge,
  onProgress,
}: {
  challenge: SignedChallenge;
  onProgress?: ProgressCallback;
}) => {
  const { nonce, difficulty, hardness } = challenge.payload;

  const solutions = [];

  onProgress?.(0);

  for (let h = 0; h < hardness; h++) {
    const solution = await computeCollisionInteger({
      input: `${nonce}:${h}`,
      expected: "0".repeat(difficulty),
    });
    solutions.push(solution);
    onProgress?.(solutions.length / hardness);
    await sleep(0);
  }

  return solutions;
};

export default computeSolutions;
