import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Botpoison from "@botpoison/browser";
import axios from "axios";
import { Formik, Form } from "formik";
import {
  TextField,
  Button,
  Typography,
  Box,
  styled,
  ThemeProvider,
  createTheme,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";

const botpoison = new Botpoison({
  publicKey: process.env.REACT_APP_BUSINESS_FORM_BOTPOISON_ID,
});

const isMobile = () =>
  window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "Assistant",
  },
});

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  // maxWidth: "450px",
  // minWidth: "280px",
  margin: isMobile() ? "0.65rem 0" : "1.2rem 0",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#CAC7B6",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#e9e928",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#e9e928",
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    marginLeft: "3px",
    textAlign: "start",
    fontWeight: "900",
    minWidth: "250px",
    top: "100%",
    left: "0",
    transform: isMobile() ? "translateY(0px)" : "translateY(2px)",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "100%",
  maxWidth: "180px",
  minWidth: "190px",
  height: "64px",
  margin: "2rem 0rem 0rem 0rem",
  backgroundColor: "#e9e928",
  color: "black",
  borderRadius: "32px",
  fontSize: "24px",
  fontWeight: 400,
  textTransform: "none",
  transition: "all 0.25s ease-in",
  "&:hover": {
    backgroundColor: "yellow",
    transform: "translateY(-3px)",
  },
  "&:disabled": {
    opacity: 0.5,
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "180px",
    minWidth: "180px",
    height: "56px",
    fontSize: "20px",
    // marginTop: "3rem",
  },
}));

const useCustomMediaQuery = (query) => {
  const theme = useTheme();
  return useMediaQuery(theme?.breakpoints?.up(query) || query);
};

function SignUpBusinessForm() {
  const { t, i18n } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const isPC = useCustomMediaQuery("md");

  useEffect(() => {
    const direction = i18n.dir();
    setIsRtl(direction === "rtl");
  }, [i18n, i18n.language]);

  const SignupSchema = Yup.object().shape({
    fullName: Yup.string()
      .required(t("business_validation.full_name.0"))
      .max(18, t("business_validation.full_name.1")),
    placeName: Yup.string()
      .required(t("business_validation.place_name.0"))
      .max(15, t("business_validation.place_name.1")),
    phoneNumber: Yup.string()
      .optional(t("business_validation.phone_number.0"))
      .max(15, t("business_validation.phone_number.1"))
      .min(9, t("business_validation.phone_number.2"))
      .matches("^[0-9-]*$", t("business_validation.phone_number.3")),
    email: Yup.string()
      .email(t("business_validation.email.0"))
      .required(t("business_validation.email.1"))
      .max(29, t("business_validation.email.2"))
      .min(14, t("business_validation.email.3")),
  });

  const formId = process.env.REACT_APP_BUSINESS_FORM_ID;
  const formSparkUrl = `https://submit-form.com/${formId}`;

  const submitForm = async (values, { resetForm }) => {
    // setSubmitting(true);
    // await postSubmission(values);
    // resetForm();
    // setSubmitting(false);

    setSubmitting(true);
    let toastId;

    try {
      toastId = toast.loading(t("toast_message.verifying_humanity"));
      const { solution } = await Promise.race([
        botpoison.challenge(),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error("Botpoison challenge timed out")),
            10000
          )
        ),
      ]);

      // Update toast to "Sending" state
      toast.update(toastId, {
        render: t("toast_message.send"),
        type: "loading",
        isLoading: true,
      });

      await postSubmission(values, solution);

      toast.update(toastId, {
        render: t("toast_message.thanks"),
        type: "success",
        isLoading: false,
        // autoClose: 8000,
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        closeButton: true,
        icon: "🚀",
      });

      resetForm();
    } catch (error) {
      console.error("Form submission failed:", error);
      toast.update(toastId, {
        render:
          error.message === "Botpoison challenge timed out"
            ? t("toast_message.verification_failed")
            : t("toast_message.error"),
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const postSubmission = async (values, solution) => {
    const { fullName, placeName, phoneNumber, email, city } = values;

    const currentLanguage = i18n.language;

    const payload = {
      full_name: fullName,
      place_name: placeName,
      phone_number: phoneNumber,
      email_address: email,
      city: city,
      language: currentLanguage,
      _botpoison: solution,
    };

    await axios.post(formSparkUrl, payload);
  };

  return (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
        <Box maxWidth="450px" margin="auto">
          <Typography
            // variant="h2"
            // variant={isMobile() ? "h5" : "h3"}
            component="h1"
            className="form_title"
            gutterBottom
            fontWeight={900}
            fontSize={isMobile() ? "1.6rem" : "2.6rem"}
            lineHeight={isMobile() ? "2.5rem" : "3.2rem"}
            maxWidth={isMobile() ? "300px" : undefined}
          >
            {t("business_page.title")}
            {/* <Box component="span" sx={{ padding: "1px 0px", display: "block" }}>
              {t("business_page.title.1")}
            </Box> */}
          </Typography>
          <Formik
            initialValues={{
              fullName: "",
              placeName: "",
              phoneNumber: "",
              email: "",
              city: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={submitForm}
          >
            {(formik) => (
              <Form
                className="signup_business_form"
                id="resettable"
                autoComplete="off"
              >
                <CustomTextField
                  fullWidth
                  name="fullName"
                  label={t("business_page.full_name")}
                  placeholder={t("business_page.full_name_placeholder")}
                  aria-placeholder={t("business_page.full_name_placeholder")}
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
                <CustomTextField
                  fullWidth
                  name="placeName"
                  label={t("business_page.place_name")}
                  placeholder={t("business_page.place_name_placeholder")}
                  aria-placeholder={t("business_page.place_name_placeholder")}
                  value={formik.values.placeName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.placeName && Boolean(formik.errors.placeName)
                  }
                  helperText={
                    formik.touched.placeName && formik.errors.placeName
                  }
                />
                {isPC ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomTextField
                        fullWidth
                        name="city"
                        label={t("business_page.city")}
                        placeholder={t("business_page.city_placeholder")}
                        aria-placeholder={t("business_page.city_placeholder")}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        fullWidth
                        name="phoneNumber"
                        type="tel"
                        label={t("business_page.phone_number")}
                        placeholder={t(
                          "business_page.phone_number_placeholder"
                        )}
                        aria-placeholder={t(
                          "business_page.phone_number_placeholder"
                        )}
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <CustomTextField
                      fullWidth
                      name="city"
                      label={t("business_page.city")}
                      placeholder={t("business_page.city_placeholder")}
                      aria-placeholder={t("business_page.city_placeholder")}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                    />
                    <CustomTextField
                      fullWidth
                      name="phoneNumber"
                      type="tel"
                      label={t("business_page.phone_number")}
                      placeholder={t("business_page.phone_number_placeholder")}
                      aria-placeholder={t(
                        "business_page.phone_number_placeholder"
                      )}
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                    />
                  </>
                )}
                <CustomTextField
                  fullWidth
                  name="email"
                  type="email"
                  label={t("business_page.email")}
                  placeholder={t("business_page.email_placeholder")}
                  aria-placeholder={t("business_page.email_placeholder")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <Box className="soon">
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 400, fontSize: "16px", color: "#f2f2f2" }}
                  >
                    {t("business_page.more_countries")}
                  </Typography>
                </Box>
                <SubmitButton
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty || submitting}
                >
                  {submitting
                    ? t("business_page.business_email_btn_process")
                    : t("business_page.business_email_btn")}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default SignUpBusinessForm;
