import { useEffect } from "react";
import cookies from "js-cookie";

const languages = [
  {
    code: "he",
    name: "עברית",
    country_code: "il",
    dir: "rtl",
  },
  {
    code: "en",
    name: "English",
    country_code: "us",
  },
];
export const Switcher = () => {
    const currentLanguageCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || "ltr";
        document.documentElement.setAttribute("lang", currentLanguage.code);
      }, [currentLanguage]);
};
