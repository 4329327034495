import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AppSelectPlacesPng from "../assets/en/nightly-features-select-places.png";
import AppSelectPlacesMobilePng from "../assets/en/nightly-features-select-places-mobile.png";
import AppSearchPlacesPng from "../assets/en/nightly-features-search.png";
import AppVideoScreenPlacesPng from "../assets/en/nightly-features-video-screen.png";
import AppVideoScreenPlacesMobilePng from "../assets/en/nightly-features-video-screen-mobile.png";
import AppEventDetailsPng from "../assets/en/nightly-features-details-screen.png";
import AppEventDetailsMobilePng from "../assets/en/nightly-features-details-screen-mobile.png";
import AppEventTicketsPng from "../assets/en/nightly-features-tickets.png";

// hebrew:
import AppSelectPlacesPng_he from "../assets/he/nightly-features-select-places-he.png";
import AppSearchPlacesPng_he from "../assets/he/nightly-features-search-he.png";
import AppVideoScreenPlacesPng_he from "../assets/he/nightly-features-video-screen-he.png";
import AppEventDetailsPng_he from "../assets/he/nightly-features-details-screen-he.png";
import AppEventTicketsPng_he from "../assets/he/nightly-features-tickets-he.png";

// import GlowImagePng from "../assets/glow.png";
import TicketGlowImagePng from "../assets/tickets-glow.png";
import WhiteGlowImagePng from "../assets/white-glow.png";
import { device } from "../breakpoints";

const Features = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const isMobile = () =>
    window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

  const getImage = (enImage, heImage) =>
    currentLanguage === "he" ? heImage : enImage;

  return (
    <FeaturesSection id="features">
      <Container>
        <FeatureItem
          reverse={false}
          marginBottom={isMobile() ? "150px" : "210px"}
        >
          <TextSection>
            <Title>{t("features_section.title_1")}</Title>
            <Description>{t("features_section.description_1")}</Description>
          </TextSection>
          <ImageWrapper>
            <GlowImage
              src={WhiteGlowImagePng}
              alt="Glow 1"
              marginLeft="50px"
              marginBottom="-200px"
              width="150%"
            />
            <StyledAppSelectPlaces
              src={
                isMobile()
                  ? getImage(AppSelectPlacesMobilePng, AppSelectPlacesMobilePng)
                  : getImage(AppSelectPlacesPng, AppSelectPlacesPng_he)
              }
              alt="Feature 1"
            />
          </ImageWrapper>
        </FeatureItem>

        <FeatureItem
          reverse={true}
          marginBottom={isMobile() ? "150px" : "210px"}
        >
          <TextSection>
            <Title>{t("features_section.title_2")}</Title>
            <Description>{t("features_section.description_2")}</Description>
          </TextSection>
          <ImageWrapper>
            <GlowImage
              src={WhiteGlowImagePng}
              alt="Glow 2"
              marginLeft="140px"
              marginBottom="0px"
              width="150%"
            />
            <GlowImage
              src={TicketGlowImagePng}
              alt="Glow 2"
              marginLeft="-150px"
              marginBottom="-35px"
              width="100%"
            />
            <StyledAppSearchPlaces
              src={getImage(AppSearchPlacesPng, AppSearchPlacesPng_he)}
              alt="Feature 2"
            />
          </ImageWrapper>
        </FeatureItem>

        <FeatureItem
          reverse={false}
          marginBottom={isMobile() ? "150px" : "210px"}
        >
          <TextSection>
            <Title>{t("features_section.title_3")}</Title>
            <Description>{t("features_section.description_3")}</Description>
          </TextSection>
          <ImageWrapper>
            <GlowImage
              src={TicketGlowImagePng}
              alt="Glow 3"
              marginLeft="-20px"
              marginBottom="0px"
              width="195%"
              opacity="0.35"
            />
            <StyledAppVideoScreenPlaces
              src={
                isMobile()
                  ? getImage(
                      AppVideoScreenPlacesMobilePng,
                      AppVideoScreenPlacesMobilePng
                    )
                  : getImage(
                      AppVideoScreenPlacesPng,
                      AppVideoScreenPlacesPng_he
                    )
              }
              alt="Feature 3"
            />
          </ImageWrapper>
        </FeatureItem>

        <FeatureItem
          reverse={true}
          marginBottom={isMobile() ? "150px" : "210px"}
        >
          <TextSection>
            <Title>{t("features_section.title_4")}</Title>
            <Description>{t("features_section.description_4")}</Description>
          </TextSection>
          <ImageWrapper>
            <GlowImage
              src={TicketGlowImagePng}
              alt="Glow 4"
              marginLeft="0px"
              marginBottom="0px"
              width="195%"
              opacity="0.4"
            />
            <StyledAppEventDetails
              src={
                isMobile()
                  ? getImage(AppEventDetailsMobilePng, AppEventDetailsMobilePng)
                  : getImage(AppEventDetailsPng, AppEventDetailsPng_he)
              }
              alt="Feature 4"
            />
          </ImageWrapper>
        </FeatureItem>

        <FeatureItem reverse={false} marginBottom={isMobile() ? "0px" : "70px"}>
          <TextSection>
            <Title>{t("features_section.title_5")}</Title>
            <Description>{t("features_section.description_5")}</Description>
          </TextSection>
          <ImageWrapper>
            <GlowImage
              src={TicketGlowImagePng}
              alt="Glow 5"
              marginLeft="50px"
              marginBottom="-80px"
              width="105%"
            />
            <StyledAppEventTickets
              src={getImage(AppEventTicketsPng, AppEventTicketsPng_he)}
              alt="Feature 5"
            />
          </ImageWrapper>
        </FeatureItem>
      </Container>
    </FeaturesSection>
  );
};

export default Features;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1c1c17;
  padding: 50px 20px;
  text-align: center;
  overflow: hidden;

  @media ${device.pc} {
    padding: 30px 20px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  gap: 20px; /* Space between text and image */

  @media ${device.pc} {
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ marginBottom }) => marginBottom};
    gap: 50px; /* Add more space between the text and the image on larger screens */
  }
`;

const TextSection = styled.div`
  flex-basis: 100%; /* Full width for mobile */
  margin-bottom: 20px;
  margin-top: -20px; /* Adjust this value to move the text section higher */
  text-align: left;
  :lang(he) {
    text-align: right;
  }

  @media ${device.pc} {
    flex-basis: 40%; /* Adjust to control the space taken by text */
    margin-bottom: 0;
    text-align: left;
    margin-top: -200px; /* Adjust this value to move the text section higher */

    :lang(he) {
      text-align: right;
    }
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 700;

  @media ${device.pc} {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 400;
  @media ${device.pc} {
    font-size: 1.15rem;
    line-height: 1.5em;
    min-width: 490px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GlowImage = styled.img`
  position: absolute;
  width: ${({ width }) => width};
  height: auto;
  z-index: 0;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  opacity: ${({ opacity }) => opacity};
`;

// const ImageSection = styled.div`
//   flex-basis: 100%; /* Full width for mobile */

//   @media ${device.pc} {
//     flex-basis: 60%; /* Adjust to control the space taken by image */
//   }
// `;

const StyledAppSelectPlaces = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
  @media ${device.pc} {
    /* margin-left: 130px; */
    /* width: 90%; */
  }
`;

const StyledAppSearchPlaces = styled(StyledAppSelectPlaces)`
  @media ${device.pc} {
    width: 90%;
    /* margin-left: -120px; */
  }
`;

const StyledAppVideoScreenPlaces = styled(StyledAppSelectPlaces)`
  width: 95%;
  @media ${device.pc} {
    width: 95%;
    /* margin-left: 150px; */
  }
`;

const StyledAppEventDetails = styled(StyledAppSelectPlaces)`
  width: 95%;
  @media ${device.pc} {
    width: 100%;
    /* margin-left: -100px; */
  }
`;

const StyledAppEventTickets = styled(StyledAppSelectPlaces)`
  @media ${device.pc} {
    width: 90%;
    /* margin-left: 120px; */
  }
`;
