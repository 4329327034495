import computeSolutions from "./computeSolutions";

declare global {
  interface Window {
    __BOTPOISON_VERSION__: string | undefined;
    __BOTPOISON_SUBMIT_LISTENER__: boolean | undefined;
    __BOTPOISON_HEARTBEAT_DISPATCHER__: boolean | undefined;
  }
}

type BotpoisonOptions = { publicKey: string };

type ChallengeOptions = { onProgress?: ProgressCallback };

export type ProgressCallback = (progress: number) => void;

export type SignedChallenge = {
  payload: {
    nonce: string;
    difficulty: number;
    hardness: number;
  };
};

const BASE_URL = "https://api.botpoison.com";

const init = () => {
  addVersion();
  addSubmitListener();
  addHeartbeatDispatcher();
};

export default class Botpoison {
  private readonly publicKey: string;

  constructor({ publicKey }: BotpoisonOptions) {
    this.publicKey = publicKey;
  }

  static init() {
    init();
  }

  async challenge(options?: ChallengeOptions) {
    const { onProgress } = options || {};
    const response = await fetch(`${BASE_URL}/challenge`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        publicKey: this.publicKey,
        hostname: location.hostname,
      }),
    });

    const challenge = (await response.json()) as SignedChallenge;

    const solutions = await computeSolutions({
      challenge,
      onProgress,
    });

    const serialized = btoa(
      JSON.stringify({
        challenge,
        solutions,
      })
    );

    return {
      solution: serialized,
    };
  }
}

const appendOrUpdateBotpoisonInput = ({
  formElement,
  id,
  name,
  value,
}: {
  formElement: HTMLElement;
  id: string;
  name: string;
  value: string;
}) => {
  let input = document.getElementById(id);
  if (!input) {
    const newInput = document.createElement("input");
    newInput.setAttribute("type", "hidden");
    newInput.setAttribute("id", id);
    newInput.setAttribute("name", name);
    formElement.appendChild(newInput);
    input = newInput;
  }
  input.setAttribute("value", value);
};

const addVersion = () => {
  if (typeof document !== "undefined") {
    window.__BOTPOISON_VERSION__ = require("../package.json").version; // eslint-disable-line @typescript-eslint/no-var-requires
  }
};

const addSubmitListener = () => {
  if (typeof document !== "undefined") {
    if (!window.__BOTPOISON_SUBMIT_LISTENER__) {
      window.__BOTPOISON_SUBMIT_LISTENER__ = true;
      document.addEventListener("submit", (event) => {
        try {
          const formEventTarget = event.target;
          if (formEventTarget) {
            const formElement = formEventTarget as HTMLFormElement;
            const publicKey = formElement.dataset.botpoisonPublicKey;
            if (publicKey) {
              event.preventDefault();
              formElement.dispatchEvent(
                new CustomEvent("botpoison-challenge-start")
              );
              const botpoison = new Botpoison({
                publicKey,
              });
              const id = `_botpoison`;
              const name = id;
              botpoison
                .challenge()
                .then(({ solution }) => {
                  formElement.dispatchEvent(
                    new CustomEvent("botpoison-challenge-success")
                  );
                  appendOrUpdateBotpoisonInput({
                    formElement,
                    id,
                    name,
                    value: solution,
                  });
                  HTMLFormElement.prototype.submit.call(formElement);
                })
                .catch((error) => {
                  console.error(error);
                  formElement.dispatchEvent(
                    new CustomEvent("botpoison-challenge-error")
                  );
                });
            }
          }
        } catch (e) {
          console.error(e);
        }
        return true;
      });
    }
  }
};

const addHeartbeatDispatcher = () => {
  if (typeof document !== "undefined") {
    if (!window.__BOTPOISON_HEARTBEAT_DISPATCHER__) {
      window.__BOTPOISON_HEARTBEAT_DISPATCHER__ = true;
      const enabled = false;
      if (!enabled) {
        return;
      }
      const dispatch = () => {
        void fetch(`${BASE_URL}/heartbeat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            hostname: location.hostname,
          }),
        });
      };
      dispatch();
      setInterval(() => {
        dispatch();
      }, 5 * 60 * 1000); // Every 15 minutes
    }
  }
};

init();
