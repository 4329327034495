import Botpoison from "@botpoison/browser";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import "./SignUpForm.css";

const botpoison = new Botpoison({
  publicKey: process.env.REACT_APP_CUSTOMER_FORM_BOTPOISON_ID,
});

const isMobile = () =>
  window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin],
});
const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "Assistant",
  },
});

const CustomTextField = styled(
  ({ focusBorderColor, borderColor, ...props }) => <TextField {...props} />
)(({ focusBorderColor, borderColor }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: borderColor,
    },
    "&:hover fieldset": {
      borderColor: borderColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: focusBorderColor,
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: focusBorderColor,
    fontWeight: "900",
  },
  "& .MuiFormHelperText-root": {
    // error fields validation
    position: "absolute",
    marginLeft: "3px",
    textAlign: "start",
    fontWeight: "900",
    minWidth: "250px",
    top: "100%", // Position below the input field
    left: "0",
    transform: isMobile() ? "translateY(0px)" : "translateY(2px)",
    // fontFamily: "Assistant",
  },
  marginBottom: isMobile() ? "8px" : "16px",
  marginRight: isMobile() ? "0" : "10px",
  marginLeft: isMobile() ? "2px" : "10px",
}));

const SignUpForm = (props) => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({ email: "", city: "" });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [isRtl, setIsRtl] = React.useState(false);
  // const [simulateError, setSimulateError] = useState(false);
  // const [isAgreed, setIsAgreed] = useState(false);

  // Update RTL state based on the current language direction
  useEffect(() => {
    const direction = i18n.dir();
    setIsRtl(direction === "rtl");
    console.log(`Current direction: ${direction}`);
  }, [i18n, i18n.language]);

  // React.useLayoutEffect(() => {
  //   document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
  // }, [isRtl]);

  const formId = process.env.REACT_APP_CUSTOMER_FORM_ID;
  const formSparkUrl = `https://submit-form.com/${formId}`;

  const validate = () => {
    const errors = {};
    if (!formData.email) {
      errors.email = t("customers_validation.required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = t("customers_validation.invalid_email");
    }

    if (!formData.city) {
      errors.city = t("customers_validation.city_required");
    } else if (formData.city.length < 4 || formData.city.length > 17) {
      errors.city = t("customers_validation.city_min");
    } else if (!/^([a-z\u0590-\u05fe ]*)+$/i.test(formData.city)) {
      errors.city = t("customers_validation.city_letters_only");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setSubmitting(true);
    // const { solution } = await botpoison.challenge();

    let solution;
    let toastId;

    // Botpoison challenge
    try {
      toastId = toast.loading(t("toast_message.verifying_humanity"));
      solution = await Promise.race([
        botpoison.challenge(),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error("Botpoison challenge timed out")),
            10000
          )
        ),
      ]);
    } catch (error) {
      console.error("Botpoison challenge failed:", error);
      toast.update(toastId, {
        render: t("toast_message.verification_failed"),
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      setSubmitting(false);
      return;
    }

    try {
      toast.update(toastId, {
        render: t("toast_message.send"),
        type: "loading",
        isLoading: true,
      });

      const currentLanguage = i18n.language;

      const payload = {
        email_address: formData.email,
        city: formData.city,
        language: currentLanguage,
        _botpoison: solution.solution,
      };
      // if (simulateError) {
      //   // Simulate an error
      //   throw new Error("Simulated error");
      // }
      await axios.post(formSparkUrl, payload);

      toast.update(toastId, {
        render: t("toast_message.thanks"),
        type: "success",
        isLoading: false,
        // autoClose: 8000,
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        closeButton: true,
        icon: "🚀",
      });

      setFormData({ email: "", city: "" });
    } catch (error) {
      console.error("Form submission failed:", error);
      toast.update(toastId, {
        render: t("toast_message.error"),
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };
  // const formStyles = {
  //   background: props.buttonColor,
  //   color: props.textColor,
  //   border: props.borderColor,
  //   error: props.colorr,
  //   btnColor: props.textColor,
  // };

  return (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
        {/* <button type="button" onClick={() => setSimulateError(!simulateError)}>
          Toggle Error Simulation
        </button> */}
        <form
          className="NotifyForm"
          id="resettable"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="input-container">
            <div className="form-group">
              <CustomTextField
                variant="outlined"
                margin="normal"
                label={t("hero_section.customer_email_field_title")}
                placeholder={t("hero_section.customer_email_placeholder")}
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                className={`email_input custom-width ${props.inputFocusClass}`}
                InputLabelProps={{
                  style: { color: props.textColor },
                }}
                InputProps={{
                  style: { color: props.textColor },
                }}
                FormHelperTextProps={{
                  style: { color: props.colorr },
                }}
                borderColor={props.borderColor}
                focusBorderColor={props.focusBorderColor}
              />
            </div>
            <div className="form-group">
              <CustomTextField
                variant="outlined"
                margin="normal"
                label={t("hero_section.customer_city_field_title")}
                placeholder={t("hero_section.customer_city_placeholder")}
                name="city"
                value={formData.city}
                onChange={handleChange}
                error={!!errors.city}
                helperText={errors.city}
                className={`city_input custom-width ${props.inputFocusClass}`}
                InputLabelProps={{
                  style: { color: props.textColor },
                }}
                InputProps={{
                  style: { color: props.textColor },
                }}
                FormHelperTextProps={{
                  style: { color: props.colorr },
                }}
                borderColor={props.borderColor}
                focusBorderColor={props.focusBorderColor}
              />
            </div>
          </div>

          {/* New checkbox for agreeing to Terms of Use and Privacy Policy */}
          {/* <div className="agreement-container">
            <label className="agreement-label">
              <input
                type="checkbox"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                required
              />
              {t("hero_section.agree_to_terms")}
              <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
                {t("hero_section.terms_of_use_link")}
              </a>
              {t("hero_section.and")}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("hero_section.privacy_policy_link")}
              </a>
            </label>
          </div> */}

          <div className="btn">
            <button
              variant="contained"
              type="submit"
              disabled={submitting}
              // buttonColor={props.submitButtonColor}
              // submitButtonHoverColor={props.submitButtonHoverColor}
              className="submit_btn"
              style={{
                backgroundColor: props.submitButtonColor,
                color: props.buttonTextColor,
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = props.submitButtonHoverColor)
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = props.submitButtonColor)
              }
            >
              {submitting
                ? t("hero_section.customer_email_btn_process")
                : t("hero_section.customer_email_btn")}
            </button>
          </div>
        </form>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default SignUpForm;
