import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { device } from "../breakpoints";

const CountdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  direction: ltr; /* Enforce left-to-right direction */
`;

const TimeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  line-height: 2.5em;

  @media ${device.pc} {
    margin: 0 15px;
    line-height: 3em;
  }
`;

const TimeValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px; /* Space between value and label */
`;

const Digit = styled.div`
  background-color: #343434bf; /* Dark background */
  border: 1px solid #54545899;
  padding: 5px;
  border-radius: 8px; /* Rounded corners */
  color: white; /* Text color */
  font-size: 30px;
  font-weight: 400;
  margin: 0 4px; /* Space between digits */

  @media ${device.pc} {
    padding: 10px;
    border-radius: 8px; /* Rounded corners */
    color: white; /* Text color */
    font-size: 40px;
  }
`;

const TimeLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
  color: white; /* Text color */
  line-height: 1.9em;
  letter-spacing: 1.5px;
  text-align: center;

  @media ${device.pc} {
    font-size: 20px;
  }
`;

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const target = new Date(targetDate);
    const difference = target.getTime() - now.getTime(); // Time difference in milliseconds

    // console.log("Current Date (now):", now);
    // console.log("Target Date (target):", target);
    // console.log("Difference in milliseconds:", difference);

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0"),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
          2,
          "0"
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      };
      // console.log("Days Left:", timeLeft.days);
      // console.log("Hours Left:", timeLeft.hours);
      // console.log("Minutes Left:", timeLeft.minutes);
      // console.log("Seconds Left:", timeLeft.seconds);
    }

    return timeLeft;
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [calculateTimeLeft, timeLeft]);

  return (
    <CountdownWrapper>
      <TimeBlock>
        <TimeValue>
          {/* render the first digit if days is three digits */}
          {timeLeft.days.length === 3 && <Digit>{timeLeft.days[0]}</Digit>}
          <Digit>
            {timeLeft.days.length === 3 ? timeLeft.days[1] : timeLeft.days[0]}
          </Digit>
          <Digit>
            {timeLeft.days.length === 3 ? timeLeft.days[2] : timeLeft.days[1]}
          </Digit>
        </TimeValue>
        <TimeLabel>{t("hero_section.count_down_days")}</TimeLabel>
      </TimeBlock>
      <TimeBlock>
        <TimeValue>
          <Digit>{timeLeft.hours[0]}</Digit>
          <Digit>{timeLeft.hours[1]}</Digit>
        </TimeValue>
        <TimeLabel>{t("hero_section.count_down_hours")}</TimeLabel>
      </TimeBlock>
      <TimeBlock>
        <TimeValue>
          <Digit>{timeLeft.minutes[0]}</Digit>
          <Digit>{timeLeft.minutes[1]}</Digit>
        </TimeValue>
        <TimeLabel>{t("hero_section.count_down_mins")}</TimeLabel>
      </TimeBlock>
      <TimeBlock>
        <TimeValue>
          <Digit>{timeLeft.seconds[0]}</Digit>
          <Digit>{timeLeft.seconds[1]}</Digit>
        </TimeValue>
        <TimeLabel>{t("hero_section.count_down_seconds")}</TimeLabel>
      </TimeBlock>
    </CountdownWrapper>
  );
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.string.isRequired,
};

export default CountdownTimer;
