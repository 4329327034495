import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../breakpoints";
import SignUpFormBusiness from "../components/business/SignUpBusinessForm";
import BusinessImg from "../assets/nightly-business-page.jpg";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { Switcher } from "../components/langswitcher/logic.jsx";
import fbIcon from "../assets/fb-social-icon.png";
import instagramIcon from "../assets/instagram-social-icon.png";
import tiktokIcon from "../assets/tiktok-social-icon.png";
import linkedinIcon from "../assets/linkedin-social-icon.png";

function Business() {
  const { t } = useTranslation();
  Switcher();
  let navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  return (
    <PageWrapper>
      <ContentSection>
        <Container>
          <AppLogo>
            <Logo
              onClick={() => {
                navigate("/");
              }}
              alt="Nightly"
            />
          </AppLogo>
          <BusinessForm>
            <SignUpFormBusiness />
          </BusinessForm>
        </Container>
        <Image>
          <SignUpImg src={BusinessImg} alt="Business" />
        </Image>
      </ContentSection>
      <SocialSection>
        <SocialContent>
          <SocialText>{t("footer_section.social_title")}</SocialText>
          <SocialIcons>
            <SocialIcon
              href="https://www.linkedin.com/company/nightlytech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={linkedinIcon} alt="LinkedIn" />
            </SocialIcon>
            <SocialIcon
              href="https://www.tiktok.com/@nightly.dot.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={tiktokIcon} alt="TikTok" />
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/nightly.dot.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={instagramIcon} alt="Instagram" />
            </SocialIcon>
            <SocialIcon
              href="https://www.facebook.com/groups/nightly.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={fbIcon} alt="Facebook" />
            </SocialIcon>
          </SocialIcons>
        </SocialContent>
        <Rules>
          <RulesTitle>{t("footer_section.rules_title")}</RulesTitle>
          <TermsLinks>
            <RuleLink onClick={() => handleNavigation("/terms-of-use")}>
              {t("footer_section.terms")}
            </RuleLink>
            <RuleLink onClick={() => handleNavigation("/privacy-policy")}>
              {t("footer_section.privacy_policy")}
            </RuleLink>
          </TermsLinks>
        </Rules>
      </SocialSection>
    </PageWrapper>
  );
}

export default Business;

const PageWrapper = styled.div`
  background-color: #1c1c17;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${device.pc} {
    flex-direction: row;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.8em;
  flex: 1;

  @media ${device.pc} {
    justify-content: flex-start;
    align-items: center;
    padding: 2em;
    width: 50%;
    max-width: 50%;

    :lang(he) {
      margin-right: 0;
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    margin-top: 1em;
    margin-left: 2em;
  }
`;

const AppLogo = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  margin-bottom: 1em;
  margin-top: 1.1em;
  /* align-self: flex-start; */

  @media ${device.pc} {
    width: 45%;
    cursor: pointer;
    margin-top: 0em;
    margin-bottom: 3em;
    margin-right: 3rem;
    :lang(he) {
      width: 57%;
    }
  }
`;

const BusinessForm = styled.div`
  width: 100%;
  max-width: 450px;
  margin-top: 1rem;

  @media ${device.pc} {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
`;

const Image = styled.div`
  display: none;

  @media ${device.pc} {
    display: flex;
    align-items: stretch;
    width: 50%;
  }
`;

const SignUpImg = styled.img`
  display: none;

  @media ${device.pc} {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media all and (orientation: portrait) and (hover: none) and (pointer: coarse) {
    @media (max-height: 780px) {
      display: none;
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

const SocialSection = styled.div`
  background-color: #1c1c17;
  /* color: white; */
  /* padding: 40px 0; */
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  border-top: 1px solid #c9c6be;
  @media ${device.pc} {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: left;
    justify-content: left;
    margin: 0 auto;
    :lang(he) {
      justify-content: right;
    }
  }
`;

const SocialContent = styled.div`
  max-width: 1000px;
  margin: 0 auto 3px 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  :lang(he) {
    margin-right: 1.5rem;
    /* margin-right: 15rem; */
    align-items: right;
    /* background-color: yellow; */
  }
  @media ${device.pc} {
    align-items: flex-start;
    margin: 0 6rem;
    align-items: left;
    margin-left: 15rem;

    :lang(he) {
      /* margin-right: 1.5rem; */
      margin-right: 15rem;
      align-items: right;
      /* background-color: yellow; */
    }
  }
`;

const SocialText = styled.p`
  font-size: 1.35rem;
  margin-bottom: 20px;
  margin-left: 3px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  @media ${device.pc} {
    margin-left: 0;
    font-size: 1.32rem;
    text-align: center;
  }
  :lang(he) {
    margin-right: 3px;
    text-align: right;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: left;
  gap: 40px;
  @media ${device.pc} {
    gap: 20px;
  }
  :lang(he) {
    justify-content: right;
  }
`;

// const SocialIcon = styled.a`
//   color: white;
//   font-size: 24px;
//   &:hover {
//     opacity: 0.8;
//   }
// `;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%; // Make it circular
  background-color: #333; // Add a background color
  transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0px 0px 7px #383731);
    /* filter: brightness(0) drop-shadow(0px 0px 4px #e9e928); */
    opacity: 1;
  }
`;

const SocialIconImg = styled.img`
  width: 60%;
  height: 60%;
  object-fit: contain;

  @media ${device.pc} {
    width: 55%;
    height: 55%;
    transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
    &:hover {
      transform: scale(1.1);
      filter: brightness(0) drop-shadow(0px 0px 7px #fffff);
      opacity: 1;
    }
  }
`;

const Rules = styled.div`
  margin-top: 2rem;
  margin-left: 1.5rem;
  :lang(he) {
    margin-right: 1.5rem;
  }
  @media ${device.pc} {
    margin-top: 0;
    margin-left: 0;
    margin: 0 6rem;
    margin-left: 5rem;

    :lang(he) {
      margin-right: -5rem;
    }
  }
`;

const RulesTitle = styled.p`
  font-size: 1.35rem;
  margin-bottom: 20px;
  margin-left: 3px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  @media ${device.pc} {
    font-size: 1.32rem;
  }
  :lang(he) {
    margin-right: 3px;
    text-align: right;
  }
`;

const TermsLinks = styled.div`
  color: #fff;
  text-align: left;
  font-size: 14px;
  @media ${device.pc} {
  }
  :lang(he) {
    text-align: right;
    font-size: 14px;
  }
`;

const RuleLink = styled.a`
  cursor: pointer;
  color: #e6e2da;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.65rem;
  margin-left: 3px;
  &:hover {
    text-decoration: underline;
    color: white;
  }
  :lang(he) {
    margin-right: 3px;
  }
`;
