import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Features from "../components/Features";
import Footer from "../components/Footer";
// import AidButton from "../components/AidButton";

function Home() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    <PageWrapper>
      <Navbar />
      <Hero />
      <Features />
      <Footer />
      <ScrollToTopButton show={showScroll} onClick={scrollToTop}>
        ▲
      </ScrollToTopButton>
      {/* <AidButton /> */}
    </PageWrapper>
  );
}

export default Home;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #e9e928;
  color: black;
  border: none;
  padding: 5px;
  width: 40px;
  height: 40px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s, visibility 0.3s, background-color 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  z-index: 1000;

  &:hover {
    background-color: #f1f155;
  }

  @media (max-width: 600px) {
    bottom: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    font-size: 16px;
  }
`;
