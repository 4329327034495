import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const useFormattedDate = (targetDate) => {
  const { i18n } = useTranslation();

  // Utility function to format the date
  const formatDate = (dateString, locale = "en-US") => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(locale, options);
  };

  // State to manage the formatted date
  const [formattedDate, setFormattedDate] = useState(
    formatDate(targetDate, i18n.language === "he" ? "he-IL" : "en-US")
  );

  // Effect to handle language changes
  useEffect(() => {
    const updateFormattedDate = (lng) => {
      setFormattedDate(
        formatDate(targetDate, lng === "he" ? "he-IL" : "en-US")
      );
    };

    // Register the language change listener
    i18n.on("languageChanged", updateFormattedDate);

    // Cleanup function to remove the listener
    return () => {
      i18n.off("languageChanged", updateFormattedDate);
    };
  }, [i18n, targetDate]);

  return formattedDate;
};

export default useFormattedDate;
