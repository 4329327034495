import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useTranslation, Trans } from "react-i18next";
import { device } from "../breakpoints";
import styled from "styled-components";
import fbIcon from "../assets/fb-social-icon.png";
import instagramIcon from "../assets/instagram-social-icon.png";
import tiktokIcon from "../assets/tiktok-social-icon.png";
import linkedinIcon from "../assets/linkedin-social-icon.png";

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const isMobile = () =>
    window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
  let navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const pageStyle = {
    backgroundColor: "#1C1C17",
    color: "white",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  };

  const contentStyle = {
    maxWidth: "800px",
    width: "100%",
    margin: "0 auto",
    padding: "20px",
    boxSizing: "border-box",
  };

  const titleContainerStyle = {
    textAlign: "center",
    padding: isMobile() ? "90px 0 20px" : "90px 0 40px",
  };

  const titleStyle = {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const lastUpdatedStyle = {
    fontSize: "0.9rem",
    opacity: "0.7",
  };

  const subtitleStyle = {
    fontSize: "1.8rem",
    fontWeight: "bold",
    marginTop: "30px",
    marginBottom: "15px",
  };

  const textStyle = {
    fontSize: "1rem",
    lineHeight: "1.6",
    marginBottom: "15px",
  };

  const listStyle = {
    paddingLeft: isRTL ? "0" : "18px",
    paddingRight: isRTL ? "15px" : "0",
    marginBottom: "15px",
  };

  const linkStyle = {
    color: "#4DA8DA",
    textDecoration: "none",
  };

  return (
    <div style={pageStyle}>
      <Navbar />
      <div style={contentStyle}>
        <div style={titleContainerStyle}>
          <h1 style={titleStyle}>{t("privacy_policy.title")}</h1>
          <p style={lastUpdatedStyle}>{t("privacy_policy.last_updated")}</p>
        </div>

        <h2 style={subtitleStyle}>{t("privacy_policy.introduction_title")}</h2>
        <p style={textStyle}>
          <Trans
            i18nKey="privacy_policy.introduction_text"
            components={{
              weblink: (
                <a
                  href="https://nightly.co"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={linkStyle}
                >
                  nightly.co
                </a>
              ),
            }}
          />
        </p>

        <h2 style={subtitleStyle}>
          {t("privacy_policy.information_collect_title")}
        </h2>
        <p style={textStyle}>{t("privacy_policy.information_collect_text")}</p>
        <ul style={listStyle}>
          <li>{t("privacy_policy.information_collect_list_1")}</li>
          <li>{t("privacy_policy.information_collect_list_2")}</li>
        </ul>

        <h2 style={subtitleStyle}>
          {t("privacy_policy.use_information_title")}
        </h2>
        <p style={textStyle}>{t("privacy_policy.use_information_text")}</p>
        <ul style={listStyle}>
          <li>{t("privacy_policy.use_information_list_1")}</li>
          <li>{t("privacy_policy.use_information_list_2")}</li>
        </ul>

        <h2 style={subtitleStyle}>
          {t("privacy_policy.sharing_information_title")}
        </h2>
        <p style={textStyle}>{t("privacy_policy.sharing_information_text")}</p>

        <h2 style={subtitleStyle}>{t("privacy_policy.data_security_title")}</h2>
        <p style={textStyle}>{t("privacy_policy.data_security_text")}</p>

        <h2 style={subtitleStyle}>
          {t("privacy_policy.age_restrictions_title")}
        </h2>
        <p style={textStyle}>{t("privacy_policy.age_restrictions_text")}</p>

        <h2 style={subtitleStyle}>{t("privacy_policy.user_rights_title")}</h2>
        <p style={textStyle}>
          <Trans
            i18nKey="privacy_policy.user_rights_text"
            components={{
              email: (
                <a href="mailto:support@nightly.co" style={linkStyle}>
                  support@nightly.co
                </a>
              ),
            }}
          />
        </p>

        <h2 style={subtitleStyle}>{t("privacy_policy.cookies_title")}</h2>
        <p style={textStyle}>{t("privacy_policy.cookies_text")}</p>

        <h2 style={subtitleStyle}>
          {t("privacy_policy.policy_changes_title")}
        </h2>
        <p style={textStyle}>{t("privacy_policy.policy_changes_text")}</p>

        <h2 style={subtitleStyle}>{t("privacy_policy.contact_info_title")}</h2>
        <p style={textStyle}>
          <Trans
            i18nKey="privacy_policy.contact_info_text"
            components={{
              email: (
                <a href="mailto:support@nightly.co" style={linkStyle}>
                  support@nightly.co
                </a>
              ),
            }}
          />
        </p>
      </div>
      <SocialSection>
        <SocialContent>
          <SocialText>{t("footer_section.social_title")}</SocialText>
          <SocialIcons>
            <SocialIcon
              href="https://www.linkedin.com/company/nightlytech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={linkedinIcon} alt="LinkedIn" />
            </SocialIcon>
            <SocialIcon
              href="https://www.tiktok.com/@nightly.dot.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={tiktokIcon} alt="TikTok" />
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/nightly.dot.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={instagramIcon} alt="Instagram" />
            </SocialIcon>
            <SocialIcon
              href="https://www.facebook.com/groups/nightly.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={fbIcon} alt="Facebook" />
            </SocialIcon>
          </SocialIcons>
        </SocialContent>
        <Rules>
          <RulesTitle>{t("footer_section.rules_title")}</RulesTitle>
          <TermsLinks>
            <RuleLink onClick={() => handleNavigation("/terms-of-use")}>
              {t("footer_section.terms")}
            </RuleLink>
            <RuleLink onClick={() => handleNavigation("/privacy-policy")}>
              {t("footer_section.privacy_policy")}
            </RuleLink>
          </TermsLinks>
        </Rules>
      </SocialSection>
    </div>
  );
};

export default PrivacyPolicy;

const SocialSection = styled.div`
  background-color: #1c1c17;
  /* color: white; */
  /* padding: 40px 0; */
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  border-top: 1px solid #c9c6be;
  @media ${device.pc} {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: left;
    justify-content: left;
    margin: 0 auto;
    :lang(he) {
      justify-content: right;
    }
  }
`;

const SocialContent = styled.div`
  max-width: 1000px;
  margin: 0 auto 3px 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  :lang(he) {
    margin-right: 1.5rem;
    /* margin-right: 15rem; */
    align-items: right;
    /* background-color: yellow; */
  }
  @media ${device.pc} {
    align-items: flex-start;
    margin: 0 6rem;
    align-items: left;
    margin-left: 15rem;

    :lang(he) {
      /* margin-right: 1.5rem; */
      margin-right: 15rem;
      align-items: right;
      /* background-color: yellow; */
    }
  }
`;

const SocialText = styled.p`
  font-size: 1.35rem;
  margin-bottom: 20px;
  margin-left: 3px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  @media ${device.pc} {
    margin-left: 0;
    font-size: 1.32rem;
    text-align: center;
  }
  :lang(he) {
    margin-right: 3px;
    text-align: right;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: left;
  gap: 40px;
  @media ${device.pc} {
    gap: 20px;
  }
  :lang(he) {
    justify-content: right;
  }
`;

// const SocialIcon = styled.a`
//   color: white;
//   font-size: 24px;
//   &:hover {
//     opacity: 0.8;
//   }
// `;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%; // Make it circular
  background-color: #333; // Add a background color
  transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0px 0px 7px #383731);
    /* filter: brightness(0) drop-shadow(0px 0px 4px #e9e928); */
    opacity: 1;
  }
`;

const SocialIconImg = styled.img`
  width: 60%;
  height: 60%;
  object-fit: contain;

  @media ${device.pc} {
    width: 55%;
    height: 55%;
    transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
    &:hover {
      transform: scale(1.1);
      filter: brightness(0) drop-shadow(0px 0px 7px #fffff);
      opacity: 1;
    }
  }
`;

const Rules = styled.div`
  margin-top: 2rem;
  margin-left: 1.5rem;
  :lang(he) {
    margin-right: 1.5rem;
  }
  @media ${device.pc} {
    margin-top: 0;
    margin-left: 0;
    margin: 0 6rem;
    margin-left: 5rem;

    :lang(he) {
      margin-right: -5rem;
    }
  }
`;

const RulesTitle = styled.p`
  font-size: 1.35rem;
  margin-bottom: 20px;
  margin-left: 3px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  @media ${device.pc} {
    font-size: 1.32rem;
  }
  :lang(he) {
    margin-right: 3px;
    text-align: right;
  }
`;

const TermsLinks = styled.div`
  color: #fff;
  text-align: left;
  font-size: 14px;
  @media ${device.pc} {
  }
  :lang(he) {
    text-align: right;
    font-size: 14px;
  }
`;

const RuleLink = styled.a`
  cursor: pointer;
  color: #e6e2da;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.65rem;
  margin-left: 3px;
  &:hover {
    text-decoration: underline;
    color: white;
  }
  :lang(he) {
    margin-right: 3px;
  }
`;
